exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-forums-[subforum]-js": () => import("./../../../src/pages/forums/[subforum].js" /* webpackChunkName: "component---src-pages-forums-[subforum]-js" */),
  "component---src-pages-forums-[subforum]-post-id-js": () => import("./../../../src/pages/forums/[subforum]/[postId].js" /* webpackChunkName: "component---src-pages-forums-[subforum]-post-id-js" */),
  "component---src-pages-forums-index-js": () => import("./../../../src/pages/forums/index.js" /* webpackChunkName: "component---src-pages-forums-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/indexOld.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-integrations-slack-js": () => import("./../../../src/pages/integrations/slack.js" /* webpackChunkName: "component---src-pages-integrations-slack-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-portable-js": () => import("./../../../src/pages/portable.js" /* webpackChunkName: "component---src-pages-portable-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

